import axios from 'axios';

export async function fetchCategoryData(isAscending = false) {
    const sortParam = isAscending ? 'true' : 'false';
    try {
        const response = await axios.get(`/frontend/all-categories?is_assending=${sortParam}`);
        return response.data.data;
    } catch (error) {
        console.error(error);
        throw error;  // Re-throw the error so that calling components can handle it
    }
}