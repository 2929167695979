<template>
    <div v-if="visible" class="modal empty-cart" @click.self="close">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-body d-flex jsutify-content-center align-items-center flex-column">
                    <img src="../assets/images/empty-cart.svg" alt="Cart">
                    <p>Your Com-Power cart is empty.</p>
                    <div class="btn-box d-flex gap-3 mt-3">
                        <button @click="close" class="common-btn blue">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'BaseModal',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        console.log("CartModel is mounted. Visible:", this.visible);
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                document.body.classList.add('modal-body-opened');
            } else {
                document.body.classList.remove('modal-body-opened');
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>
  
<style scoped>

</style>
