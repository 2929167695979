<template>
	<div class="wrapper">
		<header :class="{ shrink: isShrunk }">
            <div class="main-header">
                <div class="custom-container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="logo">
                            <router-link :to="{name:'Home'}"><img src="../assets/images/com-power-logo.png" alt="Com-Power"></router-link>
                        </div>
                        <div class="nav">
                            <button @click="toggleSidebar" class="menu-close-btn">
                                <img src="../assets/images/close.svg" alt="Com-Power">
                            </button>
                            <ul class="d-flex justify-content-between align-items-center"  @click="handleClick">
                                <li><router-link :to="{name:'Home'}">Main Site</router-link></li>
                                <li><router-link :to="{name:'CalibRequest'}">Calibration Request</router-link></li>
                                <li><router-link :to="{name:'CalibrationScope'}">Scope</router-link></li>
                                <li><a href="https://cal.com-power.com/">Login</a></li>                       
                            </ul>
                        </div>
                        <button @click="toggleSidebar" class="menu-box">
                            <span></span><span></span><span></span>
                        </button>
                        
                    </div>
                </div>
            </div>
        </header>
		<main>
			<slot></slot>
		</main>
		<main-footer></main-footer>
	</div>
</template>
  
<script>
    import MainFooter from '@/components/MainFooter.vue'
    export default {
        name: 'DefaultLayout',
        components: {
            MainFooter
        },
        data() {
            return {
                isShrunk: false
            };
        },
        methods: {
            handleScroll() {
                this.isShrunk = window.scrollY > 100;
            },
            toggleSidebar() {
                document.body.classList.toggle('menu-open');
            },
            handleClick(event) {
                // Handle click on any 'router-link' or 'li'
                if (event.target.tagName === 'A' || event.target.tagName === 'LI') {
                    document.body.classList.remove('menu-open');
                }
            },
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.handleScroll);
        }
    };
</script>
  
<style>

</style>