<template>
  <!-- <div id="app"> -->
    <Layout>
      <router-view />
    </Layout>
  <!-- </div> -->
</template>

<script>
  export default {
    metaInfo: {
        title: 'Default App Title',
        titleTemplate: '%s | vue-meta Example App',
        htmlAttrs: {
            lang: 'en-US'
        },
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: 'An example Vue application with vue-meta.' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]
    },
  }
</script>

